import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import { useRouter } from "next/router";

import {
  activeNavItemIdAtom,
  isHeaderPinnedAtom,
  isSiteMenuActiveAtom,
} from "@atoms/siteHeader";

const PrimaryNavigation = ({
	items = [],
	isLandingPage = false,
	headerTheme = "dark",
}): JSX.Element => {
	const isHeaderPinned = useAtomValue(isHeaderPinnedAtom);
	const [isSiteMenuActive, setIsSiteMenuActive] = useAtom(isSiteMenuActiveAtom);
	const [activeNavItemId, setActiveNavItemId] = useAtom(activeNavItemIdAtom);

	//console.log(items);
	const router = useRouter();

	const _Item = ({ item }) => {
		const handleClick = (e) => {
			const activeItemChildren = items.filter((i) => i.parent?.id === item.id);
			if (item.id == activeNavItemId) {
				setIsSiteMenuActive(false);
				setActiveNavItemId(null);
			} else if (
				activeItemChildren.length === 0 &&
				item.target.type === "url"
			) {
				e.preventDefault();
				setIsSiteMenuActive(false);
				setActiveNavItemId(null);
				window.open(item.target.url);
			} else if (activeItemChildren.length === 0 && item.target?.url) {
				e.preventDefault();
				setIsSiteMenuActive(false);
				setActiveNavItemId(null);
				router.push(
					item.target.element?.uri
						? "/" + item.target.element.uri
						: item.target.url
				);
			} else {
				setActiveNavItemId(item.id);
				const activeItemChildren = items.filter(
					(i) => i.parent?.id === item.id
				);
				setIsSiteMenuActive(activeItemChildren.length > 0);
			}
		};

		const handleFocus = () => {};

		const isActive = activeNavItemId === item.id && !isLandingPage;
		const activeItemChildren = items.filter((i) => i.parent?.id === item.id);

		return (
			<>
				<a
					href={
						(
							activeItemChildren.length > 0
								? undefined
								: item.target.element?.uri
						)
							? "/" + item.target.element.uri
							: item.target.url
					}
					className={clsx(
						"PrimaryNavigation__item",

						"block",
						"px-2",
						"whitespace-nowrap",
						"transition-colors",

						"focus:text-black-01",
						"cursor-pointer",
						{
							"hover-underline": !isActive,
							"text-black-01": headerTheme === "dark",
							"text-white-01":
								headerTheme === "light" &&
								!isSiteMenuActive &&
								!isHeaderPinned &&
								!isActive,
							"text-purple-01 underline underline-offset-2": isActive,
						},
						{
							// if not currently acive when site menu is open
						}
					)}
					onFocus={handleFocus}
					onClick={handleClick}
				>
					<span className={clsx("py-[5px]")}>
						{item.title ? item.title : item.target.text}
					</span>
				</a>
				<span
					className={clsx(
						"PrimaryNavigation__itemSeparator",
						{
							"text-white-03": headerTheme === "light" && !isActive,
						},
						"transition-colors",
						"last-of-type:hidden",
						"hidden xl:block"
					)}
				>
					/
				</span>
			</>
		);
	};

	const parentItems = items.filter((i) => i.level === 1);
	const navItems = parentItems.length > 0 ? parentItems : items;

	return (
		<nav className={clsx("PrimaryNavigation")}>
			<div className={clsx("PrimaryNavigation__items", "flex group")}>
				{navItems.map((item, index) => {
					return <_Item key={index} item={item} />;
				})}
			</div>
		</nav>
	);
};

export default PrimaryNavigation;
