import { Box, Text } from "@chakra-ui/react";
import { ArrowRight } from "@components/Icons";
import Target from "@components/Target";
import parse from "html-react-parser";
import { useState } from "react";

interface IProps {
	persistentNav;
}

const TopBar = ({ persistentNav }: IProps): JSX.Element => {
	const [hover, setHover] = useState(false);

	const { eventDescription, persistentNavColorScheme, topBarContent, target } =
		persistentNav;

	let topBarText = null;

	if (topBarContent) {
		topBarText = topBarContent.replace(/<\/*p>/g, "");
	}

	const handleClick = (text, url) => {
		window.dataLayer?.push({
			event: "event",
			eventProps: {
				category: "Top Bar",
				action: eventDescription ? eventDescription : text,
				label: url,
			},
		});
	};

	const colors = {
		"#0D1418": {
			//Black-03
			textColor: "#ffffff",
			backgroundColor: "#0D1418",
			ctaColor: "#B2F5E2",
		},
		"#B9BAED": {
			//Purple-03
			textColor: "#0D1418",
			backgroundColor: "#B9BAED",
			ctaColor: "#483698",
		},
		"#A7DED2": {
			//Green-04
			textColor: "#0D1418",
			backgroundColor: "#A7DED2",
			ctaColor: "#6863F2",
		},
		"#FEFAEB": {
			//Beige-04
			textColor: "#0D1418",
			backgroundColor: "#FEFAEB",
			ctaColor: "#924E8C",
		},
	};

	const { textColor, backgroundColor, ctaColor } =
		colors[persistentNavColorScheme];

	return (
		<Box
			className="TopBar"
			textAlign="center"
			backgroundColor={backgroundColor}
			color={textColor}
			p="3"
			pos={"sticky"}
			top={0}
			zIndex={55}
		>
			{!!topBarText && parse(topBarText)}

			<Box
				display="inline-block"
				borderColor={textColor}
				borderLeft={"2px solid " + textColor}
				ml="3"
				color={ctaColor}
			>
				<Target target={target} className="TopBar__target">
					<Box
						className="eventContainer"
						onClick={target && (() => handleClick(target.text, target.url))}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
					>
						<Text display="inline-block" ml="3">
							{target.text}
						</Text>
						<Box
							className="TopBar__targetIcon"
							display="inline-Block"
							ml="4"
							transitionProperty="transform"
							transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
							transitionDuration="150ms"
							transform={hover && "translateX(.25rem)"}
						>
							<ArrowRight />
						</Box>
					</Box>
				</Target>
			</Box>
		</Box>
	);
};

export default TopBar;
