import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import {
  isHeaderFixedAtom,
  isHeaderPinnedAtom,
  isSiteMenuActiveAtom,
  isSiteMobileMenuActiveAtom,
} from "@atoms/siteHeader";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Search } from "@components/Icons";
import LandingPageLogo from "@components/LandingPageLogo";
import Logo from "@components/Logo";
import Logotype from "@components/Logotype";
import PrimaryButton from "@components/PrimaryButton";
import { SearchProvider } from "@elastic/react-search-ui";
import { LandingPageCTA } from "@interfaces/LandingPageCTA.interface";
import { LogoType } from "@interfaces/LogoType.interface";
import { NavigationItem } from "@interfaces/NavigationItem.interface";
import { useNextRouting } from "@lib/useNextRouting";
import MobileMenuToggle from "@modules/MobileMenuToggle";
import PrimaryNavigation from "@modules/PrimaryNavigation";
import config from "@modules/search/config";
import ButtonBlock from "../../../packages/components/ButtonBlock";
import SiteSearch from "./search/SiteSearch";

interface IProps {
	navigationItems: NavigationItem[];
	callToActions: NavigationItem[];
	landingPageCallToActions: LandingPageCTA | false;
	isLandingPage: boolean;
	sectionNav: boolean;
	logo: LogoType[] | false;
	headerTheme: string;
	isTopBar: boolean;
}

const SiteHeader = ({
	navigationItems,
	callToActions,
	landingPageCallToActions,
	isLandingPage = false,
	logo,
	headerTheme = "dark",
	sectionNav = false,
	isTopBar,
}: IProps): JSX.Element => {
	const [topBarHeight, setTopBarHeight] = useState(null);
	const [isHeaderPinned, setIsHeaderPinned] = useAtom(isHeaderPinnedAtom);
	const isHeaderFixed = useAtomValue(isHeaderFixedAtom);
	const isSiteMenuActive = useAtomValue(isSiteMenuActiveAtom);
	const isSiteMobileMenuActive = useAtomValue(isSiteMobileMenuActiveAtom);
	const router = useRouter();
	const combinedConfig = useNextRouting(
		config("combined", ["!https://abnormalsecurity.com/results"]),
		`${router.asPath}`
	);

	useEffect(() => {
		if (document.querySelector("div.TopBar")) {
			const resizeObserver = new ResizeObserver((event) => {
				setTopBarHeight(event[0].contentBoxSize[0].blockSize);
			});
			resizeObserver.observe(document.querySelector("div.TopBar"));
		}
	});

	const modalPortalRef = React.useRef();
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		function onScroll() {
			const st = window.pageYOffset;
			setIsHeaderPinned(st >= 5);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [setIsHeaderPinned]);

	return (
		<Box
			as="header"
			className={clsx("SiteHeader", "w-full", {
				"fixed inset-x-0 z-50": isHeaderFixed,
				"bg-white-02":
					(isHeaderPinned && !isSiteMobileMenuActive) ||
					isSiteMenuActive ||
					sectionNav,
				"bg-transparent": !isHeaderPinned && !isSiteMenuActive && !sectionNav,
			})}
			ref={modalPortalRef}
		>
			<Box
				zIndex="1401"
				pointerEvents={isOpen ? "none" : "all"}
				className={clsx(
					"SiteHeader__content",
					"flex items-center",
					"lg:px-5",
					"relative",
					"max-w-screen-xl pl-7.5 md:pl-0 mx-auto",
					"transform transition-height",
					"h-20"
				)}
			>
				{logo && logo.length > 0 ? (
					<div className="shrink">
						<LandingPageLogo logo={logo[0]} />
					</div>
				) : (
					<Link
						href="/"
						className={clsx(
							"SiteHeader__logotype",
							"lg:ml-0 md:ml-5 h-[29px] w-[167px]",
							"hidden md:block",
							{
								"text-black-03":
									headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
								"text-white-01":
									(headerTheme === "light" &&
										!isSiteMenuActive &&
										!isHeaderPinned) ||
									isOpen,
							}
						)}
						style={{ pointerEvents: "all" }}
					>
						<Logotype />
					</Link>
				)}

				{!logo && (
					<Link
						href="/"
						className={clsx(
							"SiteHeader__logo",
							"h-[32px] w-[32px]",
							"transition-opacity",
							"block md:hidden",
							{
								block: !isSiteMobileMenuActive,
								"opacity-100": !isSiteMobileMenuActive,
								"opacity-0": isSiteMobileMenuActive,
							},
							{
								"text-black-03":
									headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
								"text-white-01":
									(headerTheme === "light" &&
										!isSiteMenuActive &&
										!isHeaderPinned) ||
									isOpen,
							}
						)}
						style={{ pointerEvents: "all" }}
					>
						<Logo />
					</Link>
				)}

				<Box
					className={clsx(
						"SiteHeader__navigation",
						"ml-auto",
						"hidden lg:block"
					)}
					visibility={isOpen ? "hidden" : null}
				>
					<PrimaryNavigation
						items={navigationItems}
						isLandingPage={isLandingPage}
						headerTheme={headerTheme}
					/>
				</Box>

				{!isLandingPage && (
					<Box
						className={clsx("ml-2", {
							"stroke-black-01":
								headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
							"stroke-white-01":
								headerTheme === "light" && !isSiteMenuActive && !isHeaderPinned,
						})}
					>
						<Button
							onClick={onOpen}
							background="none"
							// stroke={isOpen ? "#0D1418" : null}
							visibility={isOpen || isSiteMobileMenuActive ? "hidden" : null}
							_hover={{ background: "none" }}
              _active={{ background: "none" }}
              px={{ base: 4, lg: "0" }}
						>
							<Search />
						</Button>
						<SearchProvider config={combinedConfig}>
							<Modal
								isOpen={isOpen}
								onClose={onClose}
								blockScrollOnMount={false}
								portalProps={{
									containerRef: modalPortalRef,
								}}
							>
								<ModalOverlay />
								<ModalContent
									maxW="100vw"
									height="20"
									marginTop={isTopBar ? `${topBarHeight + 24}px` : 0}
									marginBottom="0"
									backgroundColor="#0D1418"
									borderRadius="none"
								>
									<ModalBody
										maxWidth="1240px"
										width="100%"
										px="5"
										mx="auto"
										py="0"
									>
										<Flex
											height="100%"
											justifyContent="right"
											alignItems="center"
											w="100%"
										>
											<SiteSearch onClose={onClose} />
											<ModalCloseButton
												position="static"
												size="lg"
												color="white"
												marginLeft={{
													base: "16px",
													lg: "112px",
													xl: "117px",
												}}
												marginRight="8px"
												borderRadius="0.125rem"
												_focus={{
													boxShadow: "0 0 0 2px black, 0 0 0 3px white",
												}}
											/>
										</Flex>
									</ModalBody>
								</ModalContent>
							</Modal>
						</SearchProvider>
					</Box>
				)}

				{!!callToActions && (
					<Box
						className={clsx(
							"SiteHeader__callToActions",
							"flex",
							"pl-4 xl:pr-0 xl:pl-6",
							"hidden lg:block"
						)}
						visibility={isOpen ? "hidden" : null}
					>
						{callToActions.map((callToAction, i) => {
							return (
								<div
									key={i}
									className={clsx("SiteHeader__callToAction inline-block", {
										"mr-3": callToActions.length != i + 1,
									})}
								>
									<PrimaryButton
                    color="black"
                    darkBackground={
                      headerTheme !== "dark" && !isSiteMenuActive && !isHeaderPinned
                    }
										target={{
											element: callToAction.element,
											type: !!callToAction.type ? "entry" : "url",
											url: callToAction.url,
											text: callToAction.title,
										}}
									/>
								</div>
							);
						})}
					</Box>
				)}

				{!!landingPageCallToActions && (
					<div
						className={clsx(
							"SiteHeader__callToActions",
							"flex",
							"pl-4 xl:pr-0 xl:pl-6",
							"hidden lg:block"
						)}
					>
						<div className={"SiteHeader__callToAction inline-block"}>
							<ButtonBlock
								buttonBackgroundColor={landingPageCallToActions.backgroundColor}
								buttonTextColor={landingPageCallToActions.textColor}
								isLandingPage={true}
								target={landingPageCallToActions.target}
							/>
						</div>
					</div>
				)}

				<div
					className={clsx(
						"SiteHeader__siteMobileMenuToggle",
						"block lg:hidden",
						"ml-auto",
						"relative z-50",
						{
							"text-black-03":
								headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
							"text-white-01":
								headerTheme === "light" && !isSiteMenuActive && !isHeaderPinned,
						}
					)}
				>
					{!isOpen && <MobileMenuToggle />}
				</div>
			</Box>
		</Box>
	);
};

export default SiteHeader;
